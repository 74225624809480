import React from "react"
import "../styles/styles.scss"
import SEO from "../components/seo"
import Header from "./../components/header"
import Copy from "./../components/copy"
import { Link } from "gatsby"

const NotFoundPage = () => {
  if (typeof window !== "undefined") {
    require("smooth-scroll")(".smooth-scroll", {
      updateURL: false,
      offset: () => {
        const header = document.getElementById("page__header")
        return header.offsetHeight
      },
    })
  }

  return (
    <div className="page-404">
      <SEO title="404 | BEKAP s.r.o. | Postaráme se o Vaše účetnictví" />
      <Header />
      <div className="page__content">
        <div className="container">
          <div className="content">
            <h2>Stránka nenalezena</h2>
            <Link title="Přejít na hlavní stránku" className="btn" to="/">
              <span className="btn__inner">Přejít na hlavní stránku</span>
            </Link>
          </div>
        </div>
      </div>
      <Copy />
    </div>
  )
}

export default NotFoundPage
